import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'
import Image from 'gatsby-image'

class Index extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title="Personal Site"
          keywords={[
            `Eduardo`,
            `Arenas`,
            `developer`,
            `ios`,
            `swift`,
            `engineer`,
            `backend`,
          ]}
        />
        <Image
          fluid={data.avatar.childImageSharp.fluid}
          alt={data.site.siteMetadata.author}
          style={{
            display: `block`,
            margin: `${rhythm(1.5)} auto`,
            borderRadius: `100%`,
            maxWidth: rhythm(10),
            boxShadow: `0 2pt 5pt grey`,
          }}
        />
        <p>
          I'm an experienced software engineer and people manager with a passion
          for iOS development. I also have firsthand experience in backend,
          frontend, and Android developement, as well as operations and cloud
          infrastructure. Currently working as Engineering Director at{' '}
          <a href="https://gc.com">GameChanger Media</a>.
        </p>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`
